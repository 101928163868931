
  import {
    defineComponent,
    ref,
    onMounted,
    computed,
    onBeforeUnmount,
  } from 'vue';
  import { useRouter } from 'vue-router';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';

  export default defineComponent({
    name: 'auctions-property-types-listing',
    components: {
      Datatable,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('propertyTypesListing'), [
          translate('auctions'),
        ]);
      });

      onBeforeUnmount(() => {
        store.dispatch(Actions.RESET_AUCTION_PROPERTY_TYPES_STORE);
      });

      await store.dispatch(Actions.GET_AUCTION_PROPERTY_TYPES);
      const loading = ref(false);
      const tableHeader = ref([
        {
          name: translate('idColumn'),
          key: 'id',
          sortable: true,
        },
        {
          name: translate('propertyTypeNameEn'),
          key: 'titleEn',
          sortable: true,
        },
        {
          name: translate('propertyTypeNameAr'),
          key: 'titleAr',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
        },
      ]);
      const toggleStatusProperty = async (type) => {
        const deletedMessage = type.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          loading.value = true;
          await store.dispatch(Actions.DELETE_AUCTION_PROPERTY_TYPE, type.id);
          await store.dispatch(Actions.GET_AUCTION_PROPERTY_TYPES);
          loading.value = false;
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = type.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
        }
      };

      const editProperty = (id) => {
        router.push({ name: 'auction-property-types-editing', params: { id } });
      };

      const goTo = (pageName) => {
        router.push({ name: pageName });
      };

      const pageChanged = async (val) => {
        loading.value = true;
        await store.dispatch(
          Actions.UPDATE_AUCTION_PROPERTY_TYPE_CURRENT_PAGE,
          val
        );
        await store.dispatch(Actions.GET_AUCTION_PROPERTY_TYPES);
        loading.value = false;
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        tableData: computed(() => store.getters.auctionPropertyTypesList),
        tableHeader,
        toggleStatusProperty,
        translate,
        totalItems: computed(() => store.getters.auctionPropertyTypesListCount),
        pageChanged,
        goTo,
        editProperty,
        loading,
        can,
      };
    },
  });
